<template>
  <div :class="{ 'c-dark-theme': true, animated: false, fadeIn: true }">
    <BCard style="min-width:440px;max-width:500px" border-variant="dark">
      <div slot="header">
        <!--        <BIconOctagonFill  class="mr-1"/>-->
        <BBadge :variant="signalColor"> {{tank.tankCode}} </BBadge>
        <BBadge :variant="signalColor" class="ml-1 mr-1" style="min-width:100px"> {{tank.name}} </BBadge>
        <BBadge class="mr-1" :style="{backgroundColor: oilColors[tank.oilCode], color: '#222222'}">
          {{ oilCodes[tank.oilCode] }}
        </BBadge>
        <BBadge :variant="statusVariant" :class="{ 'blink': statusVariant!=='info'}">{{ statusMsg }}</BBadge>

        <div class="card-header-actions">
          <BRow>
            <BCol>
              <BButtonGroup size="sm" v-if="tank.tankUse==='1'">
                <BButton class="mr-1" variant="outline-danger" @click="setInout('I')" :disabled="inout.sts==='O'">
                  {{ inout.sts==='I'?`입고종료`:`입고시작` }}
                </BButton>
                <BButton variant="outline-warning" @click="setInout('O')" :disabled="inout.sts==='I'">
                  {{ inout.sts==='O'?`출고종료`:`출고시작` }}
                </BButton>
              </BButtonGroup>
            </BCol>
          </BRow>

        </div>
      </div>


      <!--  Inout progress bar -- BEGIN -->
      <BRow v-if="inout.sts!=='N'" class="mb-2">
        <BCol sm="4" class="p-0" style="max-width: 130px">
          <BProgress :max="100" height="2rem" show-value>
            <BProgressBar :value="volPercent"
                          :animated="true"
                          :variant="progressVariant(volPercent)"/>
          </BProgress>
        </BCol>
        <BCol class="p-0 ml-1">
          <BInputGroup size="sm" class="small">
            <BButton size="sm" variant="outline-danger"  style="min-width:100px">
              <strong :class="{blink: true}">{{ trans(diffOvm, 'v', volUnit) }}</strong>
            </BButton>

            <BFormInput size="sm" :placeholder="`기준량(${unitSign(volUnit)}) 설정`" type="number"
                        v-model="inputTargetVol">
            </BFormInput>

            <BInputGroupAppend>
              <BButton variant="success" @click="setInoutVolume"><BIconSave2/></BButton>
              <BButton variant="danger" @click="setInout('N')"><BIconTrash2/></BButton>
            </BInputGroupAppend>
          </BInputGroup>
        </BCol>
      </BRow>
      <!--  Inout progress bar -- END -->



      <BRow>
        <BCol class="p-0 mb-1 text-center">
          <BButtonGroup size="sm">
            <BButton variant="info" @click="toggleUnit">용량 <BBadge>{{ unitSign(volUnit) }}</BBadge> </BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:80px" >
              <b>{{ trans(tank.tankRealVol, 'v', volUnit) }}</b>
            </BButton>
            <BButton variant="info">유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:80px">
              <b>{{ trans(pk.ovm, 'v', volUnit) }}</b>
            </BButton>
            <BButton variant="info">충족율 </BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:60px">
              {{ comma(pk.tvp,0) }} %
            </BButton>
          </BButtonGroup>
          <!--// 1-넘침, 2-부족, 3-화재, 4-수분, 5-누유, 6-넘침2, 7-부족2, 8-센서오류, 9-패킷오류-->
          <WidgetEvents class="mt-1" :events="events" :key="eventKey"/>

        </BCol>
      </BRow>

      <BRow>
        <BCol cols="7" ref="container" class="p-0">
        </BCol>
        <BCol cols="5">
          <BTableSimple small class="float-right small text-nowrap text-right font-weight-bolder"
                        style="width:120px">
            <BTr>
              <BTd style="min-width:50px">유위 <BBadge>{{ unitSign(lenUnit) }}</BBadge></BTd>
              <BTd style="min-width:70px"><BBadge :variant="colors.text.oil[status]">{{ trans(pk.ohr,'l',lenUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>수위 <BBadge>{{ unitSign(lenUnit) }}</BBadge></BTd>
              <BTd><BBadge :variant="colors.text.wtr[status]">{{ trans(pk.whr,'l',lenUnit) }}</BBadge> </BTd>
            </BTr>
            <BTr>
              <BTd>측정유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd> <BBadge :variant="colors.text.oil[status]">{{ trans(pk.ovm, 'v', volUnit) }}</BBadge> </BTd>
            </BTr>
            <BTr>
              <BTd>환산유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge>{{ trans(pk.ovc,'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>보정유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge>{{ trans(pk.ovcr,'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>수분량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge> {{ trans(pk.wv, 'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>평균 <BBadge>{{ unitSign(tmpUnit) }}</BBadge></BTd>
              <BTd><BBadge> {{ trans(pk.avgTm, 't', tmpUnit) }} </BBadge></BTd>
            </BTr>
            <BTr>
              <BTd colspan="2">
                <BButtonGroup>
                  <BButton @click="toggleDetail" class="small" size="sm" variant="outline-info">온도센서</BButton>
                  <BButton @click="toggleUnit" class="small ml-2" size="sm" variant="outline-info">단위변경</BButton>
                </BButtonGroup>
              </BTd>
<!--              <BTd>-->
<!--                <BBadge>{{ pk.useTms.toString() }}</BBadge>-->
<!--              </BTd>-->
            </BTr>
          </BTableSimple>
        </BCol>
      </BRow>
      <BCard v-show="detailTmShow" no-body>
        <BTableSimple small class="small text-nowrap">
          <BTr class="text-center">
            <BTd>온도1</BTd>
            <BTd>온도2</BTd>
            <BTd>온도3</BTd>
            <BTd>온도4</BTd>
            <BTd>온도5</BTd>
            <BTd>온도6</BTd>
<!--            <BTd>온도7</BTd>-->
<!--            <BTd>온도8</BTd>-->
<!--            <BTd>온도9</BTd>-->
          </BTr>
          <BTr class="text-center font-weight-bolder">
            <BTd>{{ pk.tm1 }}</BTd>
            <BTd>{{ pk.tm2 }}</BTd>
            <BTd>{{ pk.tm3 }}</BTd>
            <BTd>{{ pk.tm4 }}</BTd>
            <BTd>{{ pk.tm5 }}</BTd>
            <BTd>{{ pk.tm6 }}</BTd>
            <BTd>{{ pk.tm7 }}</BTd>
            <BTd>{{ pk.tm8 }}</BTd>
            <BTd>{{ pk.tm9 }}</BTd>
          </BTr>
        </BTableSimple>
        <BTableSimple small class="small text-nowrap">
          <BTr class="text-center"><BTd>평균온도(C)</BTd><BTd>평균온도(F)</BTd><BTd>최고온도</BTd><BTd>최저온도</BTd></BTr>
          <BTr class="text-center font-weight-bold">
            <BTd>{{ pk.avgTm }}</BTd>
            <BTd>{{ pk.avgTmF }}</BTd>
            <BTd>{{ pk.maxTm }}</BTd>
            <BTd>{{ pk.minTm }}</BTd>
          </BTr>
        </BTableSimple>
      </BCard>
    </BCard>
  </div>
</template>

<style>
.tooltip .tooltip-inner {
  text-align: left;
  max-width: 350px !important;
  width: 250px !important;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink-effect 1s step-end infinite;

  /*
  animation-name: blink-effect;
  animation-duration: 1s;
  animation-iteration-count:infinite;
  animation-timing-function:step-end;
  */
}


</style>

<script>
//------------------------------------------------------------------------
// import '../../common/HelperMixin';
import WidgetEvents from '../widget/WidgetEvents'
import * as THREE from 'three';
import {CSG} from 'three-csg-ts';
import {PacketSchema} from "@/common/schema";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
// import moment from 'moment';
import {
  getHexColor,
  getR,
  clone,
  comma,
  trans,
  apiCall, unitSign, random, speech
} from "@/common/utils";
import {IoStsMap} from "@/common/constants";
const PI = 3.14159265359;


const _normalBadge = {'1':'dark', '2':'dark', '3':'dark', '4':'dark', '5':'dark', '6':'dark', '7':'dark', '8':'dark', '9':'dark'};
const GL2LTR = 3.78541;
const LTR2GL = 0.264172;

export default {
  name: "Tank3D",
  components: {
    WidgetEvents
  },
  props: {
    width: {type: Number, default: 300},
    height: {type: Number, default: 200},
    tank: { type: Object, default: null },
    bgColor: { type: String, default: '#FFFFFF'},
    tlgPacket : { type: Object, default: null },
    initPacket : { type: Object, default: null }
  },
// °C, °F , ㎜, in, gal ====> C,F,mm,in,gal
  data () {
    return {
      tnm: '',
      windowHeight: 0,
      windowWidth: 0,

      detailTmShow: false,

      statusMsg: 'wait.',


      eventKey: 0,
      tankStatus: 'wait...',
      statusVariant: 'dark',
      tid: '',
      inout: { sts: null },

      events: {data: [], dt: null},
      // eventTip: {},
      // eventLogs: [],
      trans: trans,
      unitSign: unitSign,
      volUnit: 'l', // <--> g
      lenUnit: 'mm', // <--> i
      tmpUnit: 'c', // <---> f
      comma: comma,
      // 1-넘침,2-부족,3-화재,4-수분,5-누유,6-넘침2,7-부족2,8-센서오류,9-패킷오류
      eventColor: {
        '1':'warning',
        '2':'warning',
        '3':'danger',
        '4':'info',
        '5':'danger',
        '6':'danger',
        '7':'danger',
        '8':'info',
        '9':'warning'
      },
      badgeColor: clone(_normalBadge),

      status: 'off', // norm, warn, info, off
      signalColor: 'dark',
      pk: clone(PacketSchema),
      w: 400,
      h: 250,
      cube: null,
      renderer: null,
      scene: null,
      camera: null,
      isPacketFirst: true,
      isCamMoved: false,

      controls: null,

      oilColors: this.$store.state.codeMaps['OIL_COLOR'],
      oilCodes: this.$store.state.codeMaps['OIL_CODE'],
      nozSizeMap: this.$store.state.codeMaps['NOZ_SIZE'],
      shapeMap: this.$store.state.codeMaps['TANK_SHAPE'],
      eventCodeMap: this.$store.state.codeMaps['EVENT'],

      machine: null,
      container: null,
      renderVolume: null,

      tankColor: null,
      colors: {
        pipe: '#F0F0F0',
        water: '#0000F0',
        sensor: {
          off: '#606050',
          on: '#16A2B8',
          warn: '#FFC008',
          info: '#047BFF',
          danger: '#DC3545',
          error: '#FF0000',
          unknown: '#FF00FF'
        },
        tm: {
          off: '#001233',
          on: '#FF00FF',
          danger: '#DC3545',
          error: '#FF0000',
          unknown: '#FF00FF',
        }, // 온도계 온도

        text: {
          oil: { norm:'success', warn:'danger', info: 'info' },
          wtr: { norm:'secondary', warn:'primary', info: 'info' },
          in : 'warning',
          out: 'dark',
        }
      },

      geo: {
        tank: null,
        pipe: null,
        sensor: null,
        lvo: null,
        lvw: null,
        oil: null,
        wtr: null,
        tm : null,
        roof: null,
        roofDeco: null,
        bottom: null
      },

      mat: {
        tank: null,
        pipe: null,
        sensor: null,
        lvo: null,
        lvw: null,
        oil: null,
        wtr: null,
        roof: null,
        roofDeco: null,
        bottom: null
      },

      mesh: {
        tank: null,
        pipe: null,
        sensor: null,
        oil: null,
        wtr: null,
        lvo: null,    // render_volume_2 에서만 사용함 (유위, 박스지오메트리)
        lvw: null,    // render_volume_2 에서만 사용함 (수위, 박스지오메트리)
        oilVol: null, // render_volume_2 에서만 사용함 (최종유량)
        wtrVol: null, // render_volume_2 에서만 사용함 (최종수분)
        roof: null,
        roofDeco: null,
        bottom: null
      },
      tankOpenAngle: getR(45),
      tankCloseAngle: getR(280),

      tmMater: {}, // 온도계 Mater
      tmMesh: {}, // 온도계 메쉬

      camPos: {x:0, y:0, z:0},

      roofH: 0,
      bottomH: 0,

      senH: 11,
      tankH: 9,
      tankR: 9,
      oilColor: 0,
      oilH: 0.0,
      wtrH: 0.0,
      nozSize: 0,

      oilVol: 0,
      wtrVol: 0,
      diffOvm: 0, // inout differences
      targetVol: 0, // inout differences
      inputTargetVol: null,
      volPercent: 0,


    }

  },
  created(){
    console.log("--- Tank3D --- created---------------------");
    this.volUnit = this.tank.unitOfVol?this.tank.unitOfVol:'l'
    this.lenUnit = this.tank.unitOfLen?this.tank.unitOfLen:'mm'
    this.tmpUnit = this.tank.unitOfTmp?this.tank.unitOfTmp:'c'
  },

  computed: {},

  async mounted() {
    console.log("--- Tank3D --- mounted---------------------");
    try{
      this.w = this.width;
      this.h = this.height;

      this.isPacketFirst = true;
      this.isCamMoved = false;

      if( this.tank ) {
        this.tnm = this.tank.name;
        this.init(this.tank.tankShape);
        this.initCamera( this.tank.tankShape );
        switch(this.tank.tankShape){
          case '1':
          case '3':
            this.renderVolume = this.render_volume_1_3;
            break;
          case '2':
            this.renderVolume = this.render_volume_2;
            break;
          default:
            this.renderVolume = this.render_volume_etc;
        }
        await this.getInout();
      }

      if(this.initPacket) this.renderVolume( this.initPacket );

      // this.moveCameraAction();
      // this.animate();
      // this.onWindowResize();
    }catch(err){
      console.log(err);

    }

  },


  methods: {
    progressVariant(v){
      if(!v) return 'dark';

      if(v<10) return 'info';
      else if(v < 40) return 'primary';
      else if(v < 80) return 'success';
      else if(v < 95) return 'warning';
      else if(v >= 95) return 'danger';
      else return 'info';
    },

    toggleUnit(){
      if(this.volUnit==='l') this.volUnit='g'
      else this.volUnit='l';

      if(this.lenUnit==='mm') this.lenUnit='i'
      else this.lenUnit='mm';

      if(this.tmpUnit==='c') this.tmpUnit = 'f';
      else this.tmpUnit='c';

    },
    init: function(shape){
      // 1: Exclusive, 2: 실린더(옆으로누운), 3:Cone roof, 4, Tank lorry, 5, pipeline, 6,drum
      const tank = this.tank;
      console.log(`--- init Tank3D - TID : [${tank.tid}] ${this.shapeMap[tank.tankShape]}`)
      console.log( '######################----------',tank.tid, tank.tankShape,'------ ########################' );
      if(tank.machine===null){
        console.error(`--- init Tank3D - TID : [${tank.tid}] Machine Not found`);
        return;
      }

      this.tid = tank.tid;
      this.machine = tank.machine;

      this.senH = this.machine.sensorLen / 1000;
      this.tankH = tank.tankHeight / 1000;
      this.tankR = tank.tankWidth / 2 / 1000;

      let nozSize = this.nozSizeMap[ tank.nozSize ];
      this.nozSize = Number(nozSize.replace(/[^0-9]/g, "")) / 1000;
      this.tankColor = getHexColor( tank.color );
      this.oilColor = getHexColor( this.oilColors[tank.oilCode] )
      // console.log( 'tank-color =', tank.color, ', oil-color =', this.oilColors[tank.oilCode] );

      /*console.log( tank.tid,'senH    --->', this.senH )
      console.log( tank.tid,'tankH   --->', this.tankH )
      console.log( tank.tid,'tankR   --->', this.tankR )
      console.log( tank.tid,'nozSize --->', this.nozSize )*/


      this.container = this.$refs['container'];
      this.scene = new THREE.Scene();

      this.camera = new THREE.PerspectiveCamera(
        55, // 45가 눈이보는 시야각과 유사
        this.w / this.h,
        0.4,
        2000); // 이설정 바꾸지 말것

      this.camPos.x = (shape==='2')? 0:5 // 오른쪽 으로 이동
      this.camPos.y = (shape==='2')? this.tankH + 1 : this.tankH + 8;
      this.camPos.z = (shape==='2')? this.tankR * 2 + 20  : this.tankR * 2 + 20; // 탱크 지름 + 20m 멀리

      let cp = this.camPos;
      this.camera.position.set( cp.x , cp.y, cp.z );

      // this.camera.position.set( 0, 0, this.tankR+16 ); // 정면
      this.camera.lookAt( 0,0,0 );


      const light = new THREE.DirectionalLight( 0xFFFFFF, 1 );
      light.position.set(9,this.tankH+8,this.tankR+25);
      this.scene.add( light );
      // this.scene.add( new THREE.AmbientLight(0x000000) );
      this.scene.background = new THREE.Color( getHexColor(this.bgColor) );//


      if(shape==='1' || shape==='3') {
        this.render_shape_1_3(tank);
      }else if( shape==='2'){
        this.render_shape_2(tank);
      }
    },

    /**
     * 실린더형( like 주유소 탱크 )
     * @param tank
     */
    render_shape_2(tank){
      console.log( `====== render_tank_2( ) --- [${tank.tid}] ======`);

      const toDraws = ['tank', 'pipe', 'sensor']

      this.tankR = tank.tankHeight / 2 / 1000;
      this.tankH = tank.tankWidth / 1000;

      const th = tank.tankHeight / 1000;

      console.log( tank.tid,'senH    --->', this.senH )
      console.log( tank.tid,'tankH   --->', this.tankH )
      console.log( tank.tid,'tankR   --->', this.tankR )
      console.log( tank.tid,'nozSize --->', this.nozSize )

      this.geo.tank = new THREE.CylinderGeometry(this.tankR+0.3, this.tankR+0.3, this.tankH+0.3, 36, 10, false, this.tankOpenAngle+getR(35) , this.tankCloseAngle-getR(50) );
      this.geo.tank.rotateZ(PI * 0.5);
      this.geo.tank.rotateY(PI * 0.5);
      this.geo.pipe   = new THREE.CylinderGeometry( this.nozSize, this.nozSize, this.senH,36);
      this.geo.sensor = new THREE.CylinderGeometry(0.3,0.3,1.0,6);

      this.mat.tank   = new THREE.MeshPhysicalMaterial({color: this.tankColor, side: THREE.DoubleSide, metalness: 0.5, roughness: 1, clearcoat: 0.5, clearcoatRoughness:0, reflectivity: 1 });
      this.mat.pipe   = new THREE.MeshPhongMaterial({color: getHexColor(this.colors.pipe), opacity: 1, transparent: false} );
      this.mat.sensor = new THREE.MeshPhongMaterial({color: getHexColor(this.colors.sensor.off), opacity: 1, transparent: false} );

      toDraws.map(k=>{
        this.mesh[k] = new THREE.Mesh( this.geo[k], this.mat[k] );
        this.mesh[k].updateMatrix();
      });

      this.mesh.pipe.position.set(0, (this.senH-this.tankR*2)*0.5, 0);
      this.mesh.sensor.position.set( 0, (this.senH+1)*0.5, 0 );
      this.mesh.tank.position.set( 0, 0, 0 );

      toDraws.map(k=>{this.scene.add( this.mesh[k] );})

      /** 볼륨 초기화 드로우 ///////////////////////////////////////////////////////////////// **/

      // width = 1, height = 1, depth = 1, widthSegments = 1, heightSegments = 1, depthSegments = 1
      this.geo.lvo = new THREE.BoxGeometry( this.tankR*2, this.tankR*2,  this.tankH,1,  1, 1);
      this.geo.lvw = new THREE.BoxGeometry( this.tankR*2, this.tankR*2,  this.tankH,1,  1, 1);
      this.geo.oil = new THREE.CylinderGeometry( this.tankR, this.tankR, this.tankH,36, 1, true);
      this.geo.wtr = new THREE.CylinderGeometry( this.tankR, this.tankR, this.tankH,36, 1, true);

      this.geo.oil.rotateZ(PI * 0.5);
      this.geo.oil.rotateY(PI * 0.5);
      this.geo.wtr.rotateZ(PI * 0.5);
      this.geo.wtr.rotateY(PI * 0.5);

      this.mat.oil = new THREE.MeshLambertMaterial( {color: this.oilColor, side: THREE.DoubleSide, opacity: 0.5, transparent: true} );
      this.mat.wtr = new THREE.MeshLambertMaterial( {color: getHexColor(this.colors.water), side: THREE.DoubleSide, opacity: 0.5, transparent: true} );
      this.mat.lvo = new THREE.MeshLambertMaterial( {color: 0xdd0000, side: THREE.DoubleSide, opacity: 0.2, transparent: true} );
      this.mat.lvw = new THREE.MeshLambertMaterial( {color: 0xdddd00, side: THREE.DoubleSide, opacity: 0.2, transparent: true} );

      this.mesh.lvo = new THREE.Mesh(this.geo.lvo, this.mat.lvo );
      this.mesh.lvw = new THREE.Mesh(this.geo.lvw, this.mat.lvw );
      this.mesh.oil = new THREE.Mesh(this.geo.oil, this.mat.oil );
      this.mesh.wtr = new THREE.Mesh(this.geo.wtr, this.mat.wtr );

      /**  유량 수분량 Volume 설정 **/
      // 유위 설정
      const oilH = 3;
      const wtrH = 0.0;

      let lvoY = ( th * -1) + (oilH)

      this.mesh.oil.position.set( 0, 0, 0 );
      this.mesh.lvo.position.set( 0, lvoY, 0 );
      this.mesh.oil.updateMatrix();
      this.mesh.lvo.updateMatrix();
      // 유량+수량  매쉬 설정 oilVolume = oilFull + 유량 레벨박스 의 교집합
      const meshOil = CSG.intersect(  this.mesh.oil, this.mesh.lvo );

      // 수위 설정 -------------------------------
      let lvwY = ( th * -1) + wtrH ;
      this.mesh.wtr.position.set( 0, 0, 0 );
      this.mesh.lvw.position.set( 0, lvwY, 0 ); // 수위
      // this.scene.add( this.mesh.vol ); // for test
      this.mesh.wtr.updateMatrix();
      this.mesh.lvw.updateMatrix();

      // 최종 mesh 설정
      this.mesh.wtrVol = CSG.intersect(  this.mesh.wtr, this.mesh.lvw ); // waterVolume = waterFull + mshBox(높이조절된 유량박스) 의 교집합
      this.mesh.oilVol = CSG.subtract( meshOil, this.mesh.wtrVol ) // 여집합 실제유량 = 유량 - 수분량

      this.mesh.wtrVol.updateMatrix();
      this.mesh.oilVol.updateMatrix();

      this.scene.add( this.mesh.wtrVol );
      this.scene.add( this.mesh.oilVol );

      this.draw_thermometers(this.tankR*2, 0.15, 0 );


      this.initRenderer();


    },

    render_shape_1_3: function(tank) {
      console.log( `====== render_tank_1_3( ) --- [${tank.tid}] ======`);

      /** create geometries **/
      this.geo.tank = new THREE.CylinderGeometry(
        this.tankR+0.5, this.tankR+0.5, this.tankH,
        360, 10, true, this.tankOpenAngle , this.tankCloseAngle
      );

      this.geo.pipe   = new THREE.CylinderGeometry( this.nozSize, this.nozSize, this.senH,8);
      this.geo.sensor = new THREE.CylinderGeometry(0.5,0.5,2.0,18);
      this.geo.oil   = new THREE.CylinderGeometry(this.tankR, this.tankR, this.tankH-0.01, 36, 1  );
      this.geo.wtr   = new THREE.CylinderGeometry(this.tankR, this.tankR, 0, 36, 1);
      // ( radius = 1, widthSegments = 8, heightSegments = 6, phiStart = 0, phiLength = Math.PI * 2, thetaStart = 0, thetaLength = Math.PI ) {

      /** create materials **/
      this.mat.tank   = new THREE.MeshPhysicalMaterial(
        {color: this.tankColor, side: THREE.DoubleSide, metalness: 0.5, roughness: 1, clearcoat: 0.5, clearcoatRoughness:0, reflectivity: 1 }
      );
      this.mat.pipe   = new THREE.MeshPhongMaterial({color: getHexColor(this.colors.pipe), opacity: 1, transparent: false} );
      this.mat.sensor = new THREE.MeshPhongMaterial({color: getHexColor(this.colors.sensor.off), opacity: 1, transparent: false} );
      this.mat.oil    = new THREE.MeshLambertMaterial({color: this.oilColor, side: THREE.DoubleSide, opacity: 0.4, transparent: true} );
      this.mat.wtr    = new THREE.MeshLambertMaterial({color: 0x0000DD, opacity: 0.4, transparent: true} );
      this.mat.oil.needsUpdate = true;
      this.mat.wtr.needsUpdate = true;


      this.draw_tank_1_3_roof_bottom();

      /** create meshes **/
      Object.keys(this.geo).map(k=>{
        console.log( 'create meshes --->', k );
        if(k!=='tm' && this.geo[k]) {
          this.mesh[k] = new THREE.Mesh(this.geo[k], this.mat[k]);
          this.mesh[k].updateMatrix();
        }
      });

      console.log( '-------- create meshes done --------');

      /** set mesh positions **/
      this.mesh.pipe.position.set(0,(this.senH - this.tankH)*0.5,0);
      this.mesh.sensor.position.set( 0, this.senH/2+1, 0 );
      this.mesh.tank.position.set( 0, 0, 0 );
      this.mesh.oil.position.set( 0, 0, 0 );
      this.mesh.wtr.position.set( 0, 0, 0 );


      this.mesh.roof.position.set( 0, this.tankH*0.5+0.25+this.roofH, 0 );
      this.mesh.bottom.position.set( 0, -1*this.tankH*0.5-0.25+this.bottomH, 0 );
      if( this.mesh.roofDeco ){
        this.mesh.roofDeco.position.set( 0, (this.tankH + this.roofH)*0.5+0.1, 0 );
      }

      /** add meshes to scene except thermometers **/
      Object.keys( this.mesh ).map(key=>{
        console.log( "add mesh to scene ---", key );
        if(key!=='tm' && this.mesh[key] ) this.scene.add( this.mesh[key] );
      });

      this.draw_thermometers( this.tankH, 0.5, -0.3 );

      this.setTextTest(this.tid);

      /** create renderer **/
      this.initRenderer();

    },

    initRenderer(){
      this.renderer = new THREE.WebGLRenderer({antialias: true});
      this.renderer.setSize(this.w, this.h);
      this.renderer.sortObjects = false;
      this.container.appendChild( this.renderer.domElement );

      this.controls = new OrbitControls(this.camera, this.renderer.domElement )
      this.renderer.setAnimationLoop( this.animate )
    },

    animate: function() {
      // this.mesh.rotation.x += 0.01;
      // this.mesh.rotation.y += 0.02;
      this.render();

    },

    /**
     * 초기카메라 이동
     */
    initCamera: function( shape ) {
      let cp = this.camPos;
      let tuneZ = (shape==='2')? 6:4;
      // let tuneY = (shape==='2')? 2:0;

      let camInterVal = setInterval( ()=>{
        cp.z = cp.z - 0.5;
        if( shape!=='2') cp.y = cp.y - 0.1
        this.camera.position.set( cp.x, cp.y,  cp.z );
        this.render();
        if( cp.z <= (this.tankR * 2 + tuneZ) ) {
          clearInterval(camInterVal);
          // setTimeout(()=>this.moveCameraAction( shape ), 1000); // for test
        }

      }, 20);

    },

    /**
     * 패킷 수신시 카메라 이동
     */
    moveCameraAction: function(shape) {
      this.isPacketFirst = false;
      let cp = this.camPos;
      // shape 2 --> y = this.tankH + 2
      let tuneX = 0;
      let tuneY = 0;

      if(shape==='2'){
        tuneX = 5;
        tuneY = this.tankR;
        let camInterVal = setInterval( ()=>{
          cp.x = cp.x + 0.03;
          cp.y = cp.y - 0.1;

          console.log( 'shape 2 --- cam X,Y--->', cp.x, cp.y )
          this.camera.position.set( cp.x, cp.y, cp.z );
          // this.camera.position.set( camX, camY, this.tankR*2 + 4 ); // 정면, 탱크높이-2, 지름+3미터 멀리 카메라 위치
          // this.camera.lookAt( 0,0,0 );

          this.render();
          if( cp.y <= tuneY ) {
            clearInterval(camInterVal);
            this.isCamMoved = true;
            console.log( "--------------- camMoved ----------- shape---", shape );
          }
        }, 30);

      }else{
        tuneY = this.tankH;

        let camInterVal = setInterval( ()=>{
          if(cp.x > tuneX) cp.x = cp.x - 0.05;

          cp.y = cp.y - 0.2;

          this.camera.position.set( cp.x, cp.y, cp.z );
          // this.camera.position.set( camX, camY, this.tankR*2 + 4 ); // 정면, 탱크높이-2, 지름+3미터 멀리 카메라 위치
          // this.camera.lookAt( 0,0,0 );

          this.render();
          if( cp.y <= tuneY ) {
            clearInterval(camInterVal);
            this.isCamMoved = true;
            console.log( "--------------- camMoved ----------- shape---", shape );

          }
        }, 20);
      }



    },

    changeObjectColor: function (obj, color ){
      if(!obj) return;
      let colorHex = getHexColor(color)
      obj.color.set( colorHex );
      this.render();

    },

    /**
     * 센서 시그널 표시
     * @param type
     */
    sensorBlink: function(status='on'){
      this.changeObjectColor( this.mat.sensor,
        this.colors.sensor[status]? this.colors.sensor[status]:this.colors.sensor['unknown']
      );
      setTimeout(()=>{
        this.changeObjectColor( this.mat.sensor, this.colors.sensor.off );
      }, 500)
    },

    /**
     * 온도센서 시그널 표시
     * @param tmKey
     * @param type
     */
    tempBlink: function(tmKey, status='on'){
      // console.log( 'tmBlink()------', tmKey);
      this.changeObjectColor( this.tmMater[tmKey], this.colors.tm[status]? this.colors.tm[status]:this.colors.tm['unknown']);
      /*
      setTimeout(()=>{
        this.changeObjectColor( this.tmMater[tmKey], this.colors.tm.off );
      }, 800)
      */
    },

    /**
     * 드럼, 탱크로리, 파이프 등
     * @param tp
     */
    render_volume_etc: function(tp) {
      return tp;

    },

    render_volume_2: function(tp) {

      let oilH = tp.ohr / 1000; // 수분 플롯 높이를 포함한 높이값임
      let wtrH = tp.whr / 1000;
      const th = this.tank.tankHeight / 1000;

      console.log( `render_volume_2()--- [${tp.tid}]----> oilH=${oilH}, wtrH=${wtrH}, tankH=${this.tankH}, tankR=${this.tankR}, senH=${this.senH}`);

      /**  유량 수분량 Volume 설정 **/
      this.scene.remove( this.mesh.oilVol );
      this.scene.remove( this.mesh.wtrVol );


      let lvoY = ( th * -1) + (oilH)
      this.mesh.oil.position.set( 0, 0, 0 );
      this.mesh.lvo.position.set( 0, lvoY, 0 );
      this.mesh.oil.updateMatrix();
      this.mesh.lvo.updateMatrix();
      // 유량+수량  매쉬 설정 oilVolume = oilFull + 유량 레벨박스 의 교집합
      let meshOil = CSG.intersect(  this.mesh.oil, this.mesh.lvo );

      // 수위 설정 -------------------------------
      let lvwY = ( th * -1) + wtrH ;
      this.mesh.wtr.position.set( 0, 0, 0 );
      this.mesh.lvw.position.set( 0, lvwY, 0 ); // 수위
      // this.scene.add( this.mesh.vol ); // for test
      this.mesh.wtr.updateMatrix();
      this.mesh.lvw.updateMatrix();

      // 최종 mesh 설정
      this.mesh.wtrVol = CSG.intersect(  this.mesh.wtr, this.mesh.lvw ); // waterVolume = waterFull + mshBox(높이조절된 유량박스) 의 교집합
      this.mesh.oilVol = CSG.subtract( meshOil, this.mesh.wtrVol ) // 여집합 실제유량 = 유량 - 수분량

      this.mesh.wtrVol.updateMatrix();
      this.mesh.oilVol.updateMatrix();

      meshOil = null;

      this.scene.add( this.mesh.wtrVol );
      this.scene.add( this.mesh.oilVol );

      this.sensorBlink( this.status );

      // console.log( tp );

      tp.useTms.map(i=>{
        let tmKey = 'tm'+i;
        setTimeout( ()=>{ this.tempBlink(tmKey) }, i*50 );
      })


      this.render();

    },

    /**
     * renderStock - 재고표시
     * @param tp
     */
    render_volume_1_3: function(tp) {
      let oilH = (tp.ohr - tp.whr) / 1000;
      let wtrH = tp.whr / 1000;
      let oilY = 0, wtrY = 0;

      console.log( `render_volume_1_3()--- [${tp.tid}]----> tankH=${this.tankH}, tankR=${this.tankR}, oilH=${oilH}, wtrH=${wtrH}, senH=${this.senH}`);

      this.geo.oil.dispose();
      this.mesh.oil.geometry.dispose();

      this.geo.oil = new THREE.CylinderGeometry(this.tankR, this.tankR, oilH, 36, 1);
      this.mesh.oil.geometry = this.geo.oil;
      this.mesh.oil.updateMatrix();

      oilY = (this.tankH * -0.5) + (oilH + wtrH) * 0.5 + 0.001;
      wtrY = (this.tankH * -0.5) + (wtrH * 0.5);

      if(wtrH > oilH){
        // water float can not higher than oil one  ---> error 로 간주
        oilY = (this.senH * -0.5) + (wtrH * 0.5) + oilH
      }
      this.mesh.oil.position.set( 0, oilY, 0 );


      this.geo.wtr.dispose();
      this.mesh.wtr.geometry.dispose();

      this.geo.wtr = new THREE.CylinderGeometry(this.tankR, this.tankR, wtrH, 36, 1);
      this.mesh.wtr.geometry = this.geo.wtr;
      this.mesh.wtr.updateMatrix();
      this.mesh.wtr.position.set(0, wtrY, 0);

      this.sensorBlink(this.status);

      console.log( tp );
      tp.useTms.map(i=>{
        let tmKey = 'tm'+i;
        setTimeout( ()=>{ this.tempBlink(tmKey) }, i*80 );
      })


      // this.mesh.oil.matrixAutoUpdate = true;
      // this.mesh.wtr.matrixAutoUpdate = true;
      this.render();

    },


    /**
     * 온도계 Draw
     * @param sensorH 센서높이
     * @param tmSize 센서사이즈
     * @param tuneY 높이조절값
     */
    draw_thermometers(high, tmSize=0.5, tuneY=0 ){
      this.geo.tm    = new THREE.CylinderGeometry(this.nozSize, this.nozSize, tmSize, 36, 1);

      this.machine.temps.map(t=>{
        let tmKey = `tm${t.idx}`;
        let tlH = t.location * 0.001
        let tmH = -high * 0.5 + (tlH) + tuneY;

        this.tmMater[tmKey] = new THREE.MeshPhongMaterial({color: getHexColor(this.colors.tm.off), opacity: 1, transparent: false} );

        console.log( `render_volume_1_3()---[${this.tank.tid}]---draw_thermometers() --- tmKey = ${tmKey}, tmH = ${tmH}`);

        this.tmMesh[ tmKey ] = new THREE.Mesh( this.geo.tm,  this.tmMater[tmKey] );
        this.tmMesh[tmKey].position.set(0, tmH, 0);
        this.scene.add(this.tmMesh[tmKey]);
        console.log( this.tank.tid, 'scene added tmKey--->', tmKey );

      });
    },

    // cone roof
    /**
     * Exclusive(1),콘루프(3) 지붕 및 바닥 드로우
     */
    draw_tank_1_3_roof_bottom(){
      // 1: Exclusive, 2: 실린더(Horizontal), 3:Cone roof, 4, Tanklorry, 5, pipeline, 6,drum

      if( this.tank.tankShape==='1'){
        // 1: Exclusive

        this.geo.roof  = new THREE.CylinderGeometry(this.tankR+0.5, this.tankR+0.5, 0.5, 36, 10, false, this.tankOpenAngle , this.tankCloseAngle);
        this.geo.bottom = new THREE.CylinderGeometry(this.tankR+0.5, this.nozSize, this.tankH/9, 36, 10, false);

        this.mat.roof   = new THREE.MeshPhysicalMaterial({color: this.tankColor, side: THREE.DoubleSide, metalness: 0.5, roughness: 1, clearcoat: 0.5, clearcoatRoughness:0, reflectivity: 1 });
        this.mat.bottom   = new THREE.MeshPhysicalMaterial({color: this.tankColor, metalness: 0.5, roughness: 1, clearcoat: 0.5, clearcoatRoughness:0, reflectivity: 1 });

        this.bottomH = -1 * this.tankH/100 + -1 * this.tankR / 100

      }else if( this.tank.tankShape==='3') {
        // 3: Cone Roof
        this.geo.roof  = new THREE.CylinderGeometry(this.nozSize+0.5, this.tankR+0.8, 1, 18, 10, false, this.tankOpenAngle , this.tankCloseAngle );
        this.geo.roofDeco = new THREE.CylinderGeometry(this.tankR+0.7, this.tankR+0.7, 0.5, 18, 3, true );
        this.geo.bottom = new THREE.CylinderGeometry(this.tankR+0.5, this.tankR+0.5, 0.5, 36, 10, false);

        this.mat.roof   = new THREE.MeshPhysicalMaterial({color: this.tankColor, side: THREE.DoubleSide, metalness: 0.5, roughness: 1, clearcoat: 0.5, clearcoatRoughness:0, reflectivity: 1 });
        this.mat.roofDeco = new THREE.MeshPhysicalMaterial({color: this.tankColor, wireframe: true, wireframeLinewidth: 0.05} );
        this.mat.bottom   = new THREE.MeshPhysicalMaterial({color: this.tankColor, side: THREE.DoubleSide, metalness: 0.5, roughness: 1, clearcoat: 0.5, clearcoatRoughness:0, reflectivity: 1 });

        this.roofH = 0.3;
        this.bottomH = 0.0;


      }



    },

    /**
     * 입력 텍스트 드로우
     * @param title
     */
    setTextTest(title=''){
      const scene = this.scene;
      const render = this.render;
      const senH = this.senH;

      const loader = new FontLoader();

      loader.load( '/fonts/helvetiker_regular.typeface.json', function ( font ) {
        console.log( font );

        const color = 0x0088AA;

        // const matDark = new THREE.LineBasicMaterial( {
        //   color: color,
        //   side: THREE.DoubleSide
        // } );

        const matLite = new THREE.MeshBasicMaterial( {
          color: color,
          transparent: false,
          opacity: 1,
          side: THREE.DoubleSide
        } );

        const makerMat = new THREE.MeshBasicMaterial( {
          color: 0x33DD22,
          transparent: true,
          opacity: 1,
          side: THREE.DoubleSide
        } );


        const atgShapes = font.generateShapes( title?title:'ATG Sensor', 0.6 );
        const geometry = new THREE.ShapeGeometry( atgShapes );
        geometry.computeBoundingBox();

        const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
        geometry.translate( xMid, senH/2+2.1, 0 );

        // make shape ( N.B. edge view not visible )
        const textMesh = new THREE.Mesh( geometry, matLite );
        textMesh.position.z = 0;
        scene.add( textMesh );


        const makerShapes = font.generateShapes( 'Supported by IoTels', 0.3 );
        const makerGeo = new THREE.ShapeGeometry( makerShapes );
        makerGeo.computeBoundingBox();

        const makerX = - 0.5 * ( makerGeo.boundingBox.max.x - makerGeo.boundingBox.min.x );
        makerGeo.translate( makerX, senH/2-senH-0.6 , 0 );

        // make shape ( N.B. edge view not visible )
        const makerTextMesh = new THREE.Mesh( makerGeo, makerMat );
        makerTextMesh.position.z = 0;
        scene.add( makerTextMesh );






        // make line shape ( N.B. edge view remains visible )

        /*

                const holeShapes = [];

                for ( let i = 0; i < shapes.length; i ++ ) {

                  const shape = shapes[ i ];

                  if ( shape.holes && shape.holes.length > 0 ) {

                    for ( let j = 0; j < shape.holes.length; j ++ ) {

                      const hole = shape.holes[ j ];
                      holeShapes.push( hole );

                    }

                  }

                }

                shapes.push.apply( shapes, holeShapes );

                const lineText = new THREE.Object3D();

                for ( let i = 0; i < shapes.length; i ++ ) {

                  const shape = shapes[ i ];

                  const points = shape.getPoints();
                  const geometry = new THREE.BufferGeometry().setFromPoints( points );

                  geometry.translate( xMid, senH/2+2.1, -0.1 );

                  const lineMesh = new THREE.Line( geometry, matDark );
                  lineText.add( lineMesh );

                }

                scene.add( lineText );
        */



        render();

      })

    },

    render(){
      if(this.renderer ) this.renderer.render( this.scene, this.camera );
    },

    destroyObjects(){
      console.log("############################## Tank3D beforeDestroy       ##############################");
      console.log("############################## Tank3D beforeDestroy Start ##############################");
      // Mesh 제거
      Object.keys( this.mesh ).map(k=>{
        if(this.mesh[k]) this.scene.remove( this.mesh[k] );
        // this.mesh[k].dispose();
      });

      // 온도계 Mesh 제거
      Object.keys( this.tmMesh ).map(k=>{
        if(this.tmMesh[k]) this.scene.remove( this.tmMesh[k] );
        // this.mesh[k].dispose();
      });

      // Geometry 제거
      Object.keys( this.geo ).map(k=>{
        if(this.geo[k]) this.geo[k].dispose();
      });

      // Mater 제거
      Object.keys( this.mat ).map(k=>{
        console.log( 'dispose matter --->', k )
        if(this.mat[k]) this.mat[k].dispose();
      });

      // 온도계 Mater 제거
      Object.keys( this.tmMater ).map(k=>{
        if(this.tmMater[k]) this.tmMater[k].dispose();
      });

      // this.renderer.forceContextLoss();
      this.renderer.setAnimationLoop(null);
      this.renderer.domElement = null;
      this.renderer.dispose();
      this.renderer = null;

      console.log("############################## Tank3D beforeDestroy End   ##############################");
    },

    /**
     * call from parent component
     * @param tp
     */
    setVolume(data){
      try {
        const tp = data.payload;

        this.pk = tp;

        if (this.isPacketFirst) this.moveCameraAction( this.tank.tankShape );
        if (!this.isCamMoved) return;

        this.badgeColor = {'1':'dark', '2':'dark', '3':'dark', '4':'dark', '5':'dark', '6':'dark', '7':'dark', '8':'dark', '9':'dark'};
        this.status = 'on'

        if (tp.isEvt || tp.isErr) {
          this.status = 'warn';
          this.signalColor = tp.isErr ? 'danger' : 'warning';
          this.events.data = tp.events;
          this.events.dt = tp.dt;
        }else{
          this.events.data = [];
          this.events.dt = tp.dt;
          this.signalColor = 'info';
        }
        this.eventKey = random(1,10000);
        console.log( '----- events ------------> ', this.events );

        this.renderVolume(tp);

        this.inout = data.inout? data.inout: {sts: null};
        console.log( "inout data ---->", this.inout );

        this.setTankStatus( this.inout );

        this.setInoutSts( data.inout );

        setTimeout(() => {
          this.signalColor = "dark"
        }, 500);
      }catch(err){
        console.log(err);
      }


    },

    setTankStatus(sts){
      const {io} = sts;
      // console.log( 'setTankStatus --- inout ---> ',  inout.tid,  inout );

      this.statusMsg = IoStsMap[io];

      console.log( '----------status msg------------------>\n\n\n', IoStsMap);
      console.log( '----------status msg------------------>\n\n\n', io, this.statusMsg);
      switch(io){
        case 'O':
          this.statusVariant = 'warning';
          break;
        case 'I':
          this.statusVariant = 'danger';
          break;
        case 'R':
          this.statusVariant = 'danger';
          break;
        case 'D':
          this.statusVariant = 'warning';
          break;
        case 'N':
          this.statusVariant = 'info';
          break;
        default:
          break;
      }

    },



    async getTankSts(){
      const rs = await apiCall('get', `/api/tank/status/${this.tank.tid}`);
      if(rs.code===200){
        this.tankSts = rs.result?rs.result: { io: 'N', ioAt: null };
        const {io} = this.tankSts;

        if( io==='I' || io==='O' ) {
          this.inputTargetVol = (this.volUnit === 'g') ? Math.round(this.inout.targetVol * LTR2GL) : this.inout.targetVol;
        }

        console.log( 'tank status result -----> tid=',this.tid,'--->', rs.result  );
      }
      // await this.toastInfo(`입출고 상태 정보 업데이트`, 'info');
    },




    setInoutSts(inout){
      console.log( 'setInoutSts ---------', inout );
      this.inout = inout;
      const {sts} = inout;
      this.statusMsg = IoStsMap[sts];
      switch(sts){
        case 'O':
          this.statusVariant = 'warning';
          this.targetVol = this.inout.targetVol;
          this.diffOvm = Math.abs(this.inout.outOvm); // 양수
          this.volPercent =  Math.round(this.inout.outOvm / this.targetVol * 100)
          if( this.targetVol>0 && this.volPercent >= 100 ) speech(`${this.tank.name} 출고량 확인.`)
          break;
        case 'I':
          this.statusMsg = '입고중';
          this.statusVariant = 'danger';
          this.targetVol = this.inout.targetVol;
          this.diffOvm = this.inout.inOvm;
          this.volPercent =  Math.round(this.inout.inOvm / this.targetVol * 100 );
          if( this.targetVol>0 && this.volPercent >= 100 ) speech(`${this.tank.name} 입고량 확인.`)
          break;
        case 'N':
          this.statusMsg = '저장중';
          this.statusVariant = 'info';
          this.targetVol = 0;
          this.diffOvm = 0;
          this.volPercent = 0;
          break;
        default:
          this.statusMsg = '미확인';
          this.statusVariant = 'dark';
          break;
      }

    },

    async getInout(){
      const rs = await apiCall('get', `/api/inout/current/${this.tank.tid}`);
      if(rs.code===200){
        this.inout = rs.result;
        if( this.inout.sts!=='N') this.inputTargetVol = (this.volUnit==='g')? Math.round(this.inout.targetVol*LTR2GL):this.inout.targetVol;
        console.log( 'inout result -----> tid=',this.tid,'--->', rs.result  );
      }
      // await this.toastInfo(`입출고 상태 정보 업데이트`, 'info');
    },

    async setInout( gbn ){
      let cmd = 'B';
      let cmdName = '시작';
      try{
        console.log( "setInout() ---- inout ----------->", this.inout );
        console.log( "setInout() ---- gbn ----------->", gbn );

        let inoutStr = IoStsMap[gbn];

        if(gbn==='N'){
          if( !await this.confirmModal(`${this.tnm} ${inoutStr} 작업 취소 확인`, `${inoutStr} 작업 취소`) ) return;
          cmd = 'C';
          cmdName = '취소'
        }else if(this.inout?.sts!=='N') {
          cmd = 'E'
          cmdName = '종료';
        }

        const param = {
          reqSts: gbn,
          cmd: cmd
        }
        console.log( 'setInout sts --->', gbn, param )

        const rs = await apiCall('post', `/api/inout/${this.tank.tid}`, param);
        console.log( 'inout result ---------->', rs.result  );
        if(rs.code===200){
          this.inout = rs.result;
          this.inputTargetVol = 0;
          this.setInoutSts(this.inout);

          const msg = `${this.tnm}, ${inoutStr} ${cmdName}, 설정 완료`;
          await this.toastInfo(msg , '경고');
          speech(msg);

        }else{
          console.log( rs.message );
          await this.toastWarn(rs.message, '경고');
        }
      }catch(err){
        console.log(err);
        await this.toastError(err);
      }
    },

    async setInoutVolume(){
      try{

        const gbn = this.inout.sts;
        if(!this.inputTargetVol){
          return await this.toastInfo('기준량 입력 오류 ', '경고');
        }

        const inoutStr = (gbn==='I')? '입고':'출고';
        const unitStr = (this.volUnit==='g')? '갤런으로' : '리터로';

        const targetVol = Number(this.inputTargetVol);

        const param = {
          cmd : 'V',
          reqSts: gbn,
          volume: targetVol,
          unit: this.volUnit
        }

        console.log( 'setInout sts --->', gbn, param )
        const rs = await apiCall('post', `/api/inout/${this.tank.tid}`, param);
        console.log( 'inout result ', rs.result  );

        if(rs.code===200){
          this.inout = rs.result;
          this.setTankStatus(this.inout);
          const msg = `탱크 ${this.tank.name}, ${inoutStr} 작업 기준량, ${this.inputTargetVol} ${unitStr} 설정 되었습니다.`;
          await this.toastInfo(msg, '경고');
          speech(msg)
        }else{
          console.log( rs.message );
          await this.toastWarn(rs.message, '경고');
        }

      }catch(err){
        console.error(err);
      }
    },

    toggleDetail(){
      this.detailTmShow = !this.detailTmShow;
      /*
      this.windowHeight = window.outerHeight;
      this.windowWidth = window.outerWidth;
      if(this.detailTmShow){
        window.resizeTo( this.windowWidth, this.windowHeight+170)
      }else{
        window.resizeTo( this.windowWidth, this.windowHeight-170)
      }
      */
    },


  },
  watch:{
    tlgPacket(newTp, oldTp) {
      console.log( 'new packet ---', newTp)
      console.log( 'old packet ---', oldTp)

      this.renderVolume( newTp );

    },
    'tank': function() {
      console.log( 'change tank --->', this.tank );
      this.init();
    }

  },
  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    this.destroyObjects();


  },



}
</script>
